import { v4 } from 'uuid';
import { blacklist } from 'validator';
import { gtmFireEvent as trackingAction } from '~/helpers/gtm';
import { clone, isImage } from '~/helpers/javascript';
import { API_BROWSE } from '../../../services/app.config';
import { edit } from '~/services/template.service';

export const loadFonts = (fonts) =>
  new Promise((resolve) => {
    Promise.allSettled(
      fonts.map((f) => {
        return new Promise((res, rej) => {
          const url = `url(${f.path})`;
          const font = new FontFace(f.fontFamily, url);
          font
            .load()
            .then(function (loadedFont) {
              document.fonts.add(loadedFont);
              res();
            })
            .catch(function (error) {
              console.log('Failed to load font: ' + error);
              rej();
            });
        });
      })
    ).then(() => {
      resolve();
    });
  });

export const validateFulfilled = (status) => {
  let fulfilled = true;
  status.forEach((r) => {
    if (r.status === 'rejected') fulfilled = false;
  });

  return fulfilled;
};

export const updateProportionInVideos = (proportion, sections) => {
  const _sections = sections;

  _sections.map((section) => {
    section.id = v4();

    section.videos.map((video) => {
      video.proportion = proportion;
    });
  });

  return _sections;
};

export const sendPrompt = (prompt) =>
  new Promise((resolve, reject) => {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 120000);

    trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'request-openai-api'
    });

    fetch('https://api.openai.com/v1/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 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'
      },
      body: JSON.stringify({
        prompt: prompt,
        model: 'text-davinci-003',
        temperature: 0.7,
        max_tokens: 2500,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0
      }),
      signal: controller.signal
    })
      .then((response) => response.json())
      .then((result) => {
        clearTimeout(timeout);
        resolve(result.choices[0].text);
      })
      .catch((err) => {
        console.log('ERROR - api.openai - ', err);
        trackingAction({
          event: 'onButtonClick',
          category: 'idea-to-video',
          action: 'error-api-openai',
          params: {
            error: `${err}`
          }
        });
        reject(err);
      });
  });

export const sendPromptToGpt = (prompt, opt = {}) =>
  new Promise((resolve, reject) => {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 120000);

    fetch('https://api.openai.com/v1/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer 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'
      },
      body: JSON.stringify({
        prompt: prompt,
        model: 'text-davinci-003',
        temperature: 0.7,
        max_tokens: 2500,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0
      }),
      signal: controller.signal
    })
      .then((response) => response.json())
      .then((result) => {
        clearTimeout(timeout);
        resolve(result.choices[0].text);
      })
      .catch((err) => {
        console.log('ERROR - api.openai - ', err);
        trackingAction({
          event: 'onButtonClick',
          category: 'idea-to-video',
          action: 'error-api-openai',
          params: {
            error: `${err}`
          }
        });
        reject(err);
      });
  });

export const getVoiceOvers = (videos, selectedVoice, voices, preset) => {
  return new Promise((resolve) => {
    const promises = videos.map((video) => {
      return new Promise((resolve, reject) => {
        const voiceConfig = voices[selectedVoice.language][selectedVoice.value];

        let voiceoverString = '';
        video.scenes.forEach((scene, index) => {
          if (preset !== 'typewriter')
            voiceoverString = `${voiceoverString}<prosody rate="+15.00%">${scene.captions.replace(
              /[~*]/g,
              ''
            )}</prosody><break time="1000ms"/><bookmark name="scene_${index}"/>`;
          else
            voiceoverString = `${voiceoverString}${scene.voiceover}<break time="1000ms"/><bookmark name="scene_${index}"/>`;
        });

        const voiceoverXML =
          '<speak xmlns="http://www.w3.org/2001/10/synthesis" ' +
          'xmlns:mstts="http://www.w3.org/2001/mstts" xmlns:emo="http://www.w3.org/2009/10/emotionml" version="1.0" ' +
          'xml:lang="' +
          voiceConfig.language +
          '">' +
          '<voice name="' +
          voiceConfig.voice +
          '">' +
          '<mstts:express-as style="' +
          selectedVoice.style +
          '">' +
          voiceoverString +
          '</mstts:express-as>' +
          '</voice>' +
          '</speak>';

        trackingAction({
          event: 'onButtonClick',
          category: 'idea-to-video',
          action: 'request-voiceover-api'
        });

        let API_HOST = 'https://woxo.tech';

        if (typeof window !== 'undefined') {
          API_HOST = window.location.origin;
        }
        fetch(`${API_HOST}/api/ai/voice`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...voiceConfig,
            text: voiceoverXML
          })
        })
          .then((response) => response.json())
          .then((result) => {
            if (!result?.audio) throw new Error('empty response');
            if (!result?.audio.timepoints || result.audio.timepoints.length === 0)
              throw new Error('no timepoints');
            resolve(result);
          })
          .catch((err) => {
            console.log('ERROR - voiceover - ', err);
            trackingAction({
              event: 'onButtonClick',
              category: 'idea-to-video',
              action: 'error-voiceover',
              params: {
                error: `${err}`
              }
            });
            reject(new Error('ERROR - voiceover'));
          });
      });
    });

    Promise.allSettled(promises).then((results) => {
      resolve(results);
    });
  });
};

export const getMedias = (arr, tags) => {
  const result = [];
  arr.forEach((element) => {
    let count = 0;
    for (let i = 0; i < element.tags.length; i++) {
      if (tags.includes(element.tags[i])) {
        count++;
      }
    }
    if (count === tags.length) {
      element.medias.forEach((media) => {
        if (!result.includes(media)) {
          result.push(media);
        }
      });
    }
  });
  return result;
};

export const errorVideos = [
  'https://res.cloudinary.com/dakp804eh/video/upload/v1674536839/Woxo/Idea2video/Opps/the-good-place-idk.mp4',
  'https://res.cloudinary.com/dakp804eh/video/upload/v1674536463/Woxo/Idea2video/Opps/puppy-eyes.mp4',
  'https://res.cloudinary.com/dakp804eh/video/upload/v1674535961/Woxo/Idea2video/Opps/sorry-oops.mp4',
  'https://res.cloudinary.com/dakp804eh/video/upload/v1674535449/Woxo/Idea2video/Opps/homer-simpson-hiding.mp4',
  'https://res.cloudinary.com/dakp804eh/video/upload/v1674534466/Woxo/Idea2video/Opps/oops-facepalm.mp4',
  'https://res.cloudinary.com/dakp804eh/video/upload/v1674533755/Woxo/Idea2video/Opps/jim-carrey-oops.mp4'
];

export const updateVideosWithError = (videos, proportion) => {
  let _videos = videos;

  const mediaList = [...errorVideos];

  _videos.map((video) => {
    const _medias = mediaList.sort(() => 0.5 - Math.random());

    video.id = v4();
    video.audioTracks = [];
    video.proportion = proportion;

    video.clips.map((c, i) => {
      c.layers.map((l, lIdx) => {
        if (lIdx === 0) {
          l.path = _medias[i];
          l.type = 'woxo-video';
        } else if (typeof l.text === 'string') {
          l.text = '';
        }
      });
    });
  });

  return _videos;
};

export const updateVideoWithError = (video) => {
  const mediaList = [...errorVideos];
  const _medias = mediaList.sort(() => 0.5 - Math.random());

  let _video = video;
  _video.id = v4();
  _video.audioTracks = [];

  _video.clips.map((c, i) => {
    c.layers.map((l, lIdx) => {
      if (lIdx === 0) {
        l.path = _medias[i];
        l.type = 'woxo-video';
      } else if (typeof l.text === 'string') {
        l.text = '';
      }
    });
  });

  return _video;
};

export const buildVideoSection = (
  section,
  voiceover,
  medias,
  voiceoverAudio,
  music,
  proportion,
  preset,
  textStyles
) => {
  let _section = { ...section };

  let timesByVoiceOver = [];
  if (preset !== 'typewriter')
    timesByVoiceOver = getTimesByVoiceOver(voiceover, voiceoverAudio.audios);

  _section.videos.map((video, idx) => {
    video.id = v4();
    video.audioTracks = [];
    video.proportion = proportion;

    // --- customFont
    video.customFonts = [textStyles.customFont];

    // --- Music
    if (music.length !== 0) {
      video.audioTracks[0] = {
        path: music[idx]
      };
    }

    // --- Voice-over
    if (!voiceoverAudio.disabled) {
      const voiceOver = voiceoverAudio.audios[idx];

      // Add voice-over
      const audioTrack = {
        path: voiceOver.audio.path,
        loop: false
      };

      if (video.audioTracks.length !== 0) {
        // update volume
        // audiotrack 0 comes with the music
        // audiotrack 1 comes with the voiceover
        video.audioTracks[0].mixVolume = 0.2;
        video.audioTracks[1] = audioTrack;
      } else {
        video.audioTracks[0] = audioTrack;
      }
    }

    let _animations = [
      'ken-burns-zoom-in',
      'ken-burns-zoom-out',
      'ken-burns-zoom-slider',
      'ken-burns-zoom-in-slider',
      'ken-burns-zoom-rotate'
    ];
    _animations = _animations.sort(() => 0.5 - Math.random());
    let _animationIdx = 0;

    // --- Update videos
    video.clips.forEach((c, i) => {
      c.layers = [];

      // Add medias
      // TODO: value remove that only have 2 socks (now the plugin is not done)
      const _media =
        medias[idx].length === 2 ? (i % 2 === 0 ? medias[idx][0] : medias[idx][1]) : medias[idx][i];
      c.layers[0] = {
        ...c.layers[0],
        path: _media,
        type: isImage(_media) ? 'woxo-image' : 'woxo-video',
        resizeMode: 'cover'
      };
      if (isImage(_media))
        c.layers[0] = {
          ...c.layers[0],
          animation: true,
          animationSettings: {
            type: _animations[_animationIdx]
          }
        };

      // Duration of scenes through voiceover
      if (preset === 'typewriter') {
        const times = voiceoverAudio.audios[idx].audio.timepoints;
        c.duration =
          (i === 0 ? times[i].timeSeconds : times[i].timeSeconds - times[i - 1].timeSeconds) + 1;
      } else {
        c.duration = timesByVoiceOver[idx].scenes[i].duration;
      }

      // Add text
      if (!textStyles.hide) {
        if (preset === 'typewriter') {
          let textLayer = {
            ...textStyles,
            text: voiceover[idx].scenes[i].voiceover,
            width: 1080,
            height: 1920,
            fontFamily: textStyles.customFont.fontFamily,
            animation: true,
            animationSettings: {
              type: 'typewriter',
              duration: c.duration - 1.5
            }
          };
          c.layers[1] = textLayer;
        } else {
          const highlights = ['#00FB2F', '#FFFE00'];
          const captions = voiceover[idx].scenes[i].captions.replace(/~\s*$/, '').split('~');
          captions.forEach((caption, captionIdx) => {
            const highlighted = highlights[randomIntFromInterval(0, 1)];
            let textLayer = {
              ...textStyles,
              fontFamily: textStyles.customFont.fontFamily,
              text: caption.toUpperCase(),
              width: 1080,
              height: 1920,
              fontSize:
                caption.trim().indexOf(' ') !== -1
                  ? 90
                  : caption.indexOf('*') !== -1
                  ? caption.length <= 12
                    ? 120
                    : caption.length <= 15
                    ? 90
                    : 80
                  : caption.length <= 15
                  ? 90
                  : 80,
              backgroundColor: highlighted,
              fill:
                caption.trim().indexOf(' ') !== -1
                  ? textStyles.fill
                  : caption.indexOf('*') !== -1
                  ? highlighted
                  : textStyles.fill,
              stroke: textStyles.stroke,
              strokeWidth: 8,
              shadow: 'rgba(0,0,0,.20) 0px 0px 20px'
            };

            textLayer.start = timesByVoiceOver[idx].scenes[i].times[captionIdx].start;
            if (timesByVoiceOver[idx].scenes[i].times[captionIdx].stop)
              textLayer.stop = timesByVoiceOver[idx].scenes[i].times[captionIdx].stop;

            c.layers[captionIdx + 1] = textLayer;
          });
        }
      }
    });
  });

  _section.voiceover = voiceover;
  _section.voiceoverAudio = voiceoverAudio;
  _section.medias = medias;
  _section.music = music;
  _section.isLoading = false;
  _section.id = v4();
  // console.log('_section -- ', _section);

  return _section;
};

export const addWaterMarkInVideos = (videos, proportion = 1) => {
  let _videos = [...videos];

  const textStyle = {
    fontFamily: 'Abel-Regular',
    path: 'https://res.cloudinary.com/dakp804eh/raw/upload/v1672870891/Woxo/Idea2video/fonts/Abel-Regular.ttf'
  };

  _videos.map((v) => {
    v.proportion = proportion;
    const isFound = v.customFonts.some((f) => {
      if (f.fontFamily === textStyle.fontFamily) {
        return true;
      }
      return false;
    });
    if (!isFound) {
      v.customFonts.push({
        fontFamily: textStyle.fontFamily,
        path: textStyle.path
      });
    }

    v.clips.map((c) => {
      c.layers.push({ type: 'woxo-water-mark' });
    });
  });

  return _videos;
};

export const backgroundGradients = [
  '-webkit-linear-gradient(90deg, #4E65FF, #92EFFD)',
  '-webkit-linear-gradient(90deg, #EE9CA7, #FFDDE1)',
  '-webkit-linear-gradient(90deg, #FF5F6D, #FFC371)',
  '-webkit-linear-gradient(90deg, #009245, #FCEE21)',
  '-webkit-linear-gradient(90deg, #02AABD, #00CDAC)',
  '-webkit-linear-gradient(90deg, #2E3192, #1BFFFF)',
  '-webkit-linear-gradient(90deg, #D4145A, #FBB03B)',
  '-webkit-linear-gradient(90deg, #FF512F, #DD2476)',
  '-webkit-linear-gradient(90deg, #11998E, #38EF7D)',
  '-webkit-linear-gradient(90deg, #C6EA8D, #FE90AF)',
  '-webkit-linear-gradient(90deg, #EA8D8D, #A890FE)',
  '-webkit-linear-gradient(90deg, #D8B5FF, #1EAE98)',
  '-webkit-linear-gradient(90deg, #FF61D2, #FE9090)',
  '-webkit-linear-gradient(90deg, #BFF098, #6FD6FF)',
  '-webkit-linear-gradient(90deg, #A9F1DF, #FFBBBB)'
];

export const cleanUpJsonStringStart = (str) => {
  return str.replace(/^[^\[]+/, '');
};

export const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const parseGPTAnswer = (gptAnswer) => {
  let jsonObject;

  // Try to parse the JSON response as is
  try {
    const cleanedStartGptAnswer = cleanUpJsonStringStart(gptAnswer);
    jsonObject = JSON.parse(cleanedStartGptAnswer);
  } catch (error) {
    console.error('Error parsing INITIAL GPT-3 response:', error);
    console.log(gptAnswer);

    trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'error-parsing-initial-gpt3-answer',
      label: JSON.stringify(error),
      params: {
        error: `${error}`
      }
    });

    // If there's a parsing error, sanitize the `gptAnswer` property
    let sanitizedAnswer = blacklist(gptAnswer, '\u0000-\u001F');

    // Remove all the `]1. Script[` from the JSON
    const regex = /[^,\[]*\[/;
    const regex2 = /\][^,\]]*\[/g;
    const regex3 = /\][^,\]]*$/;
    sanitizedAnswer = sanitizedAnswer
      .replace(regex, '[')
      .replace(regex2, '][')
      .replace(regex3, ']');

    // Replace all the `][` with `,` to make it a valid JSON
    sanitizedAnswer = sanitizedAnswer.replace(/\]\[/g, ',');

    // Replace extra , at the end of the JSON
    sanitizedAnswer = sanitizedAnswer.replace(/,\s*([\]}])/g, '$1');

    // Try to parse the sanitized JSON response
    try {
      jsonObject = JSON.parse(sanitizedAnswer);
    } catch (error) {
      trackingAction({
        event: 'onButtonClick',
        category: 'idea-to-video',
        action: 'error-parsing-sanitizing-gpt3-answer',
        label: JSON.stringify(error),
        params: {
          error: `${error}`,
          gpt3Answer: sanitizedAnswer
        }
      });
      console.error('Error parsing sanitized GPT-3 response:', error);

      fetch('https://api-dev.woxo.tech/gpt-completion/logging', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            action: 'error-parsing-sanitizing-gpt3-answer',
            error: error,
            sanitizedAnswer: sanitizedAnswer,
            gptAnswer: gptAnswer
          }
        })
      })
        .then()
        .catch((e) => console.log(e));

      console.log(sanitizedAnswer);
    }
  }

  if (jsonObject === undefined) {
    trackingAction({
      event: 'onButtonClick',
      category: 'idea-to-video',
      action: 'error-parsing-sanitizing-gpt3-undefined-answer',
      params: {
        error: 'Undefined Object'
      }
    });
    console.log('Error parsing sanitized GPT-3 undefined response');

    throw 'Undefined Object';
  }

  console.log(jsonObject);

  return jsonObject;
};

export const getVideosBySections = (sections) => {
  const _sections = clone(sections);
  let _videos = [];

  _sections.forEach((section, idx) => {
    section.videos.forEach((v, i) => {
      _videos.push({
        video: v,
        sectionIdx: idx,
        videoIdx: i
      });
    });
  });

  return _videos;
};

export const getCategoryGradient = (categories, category) => {
  let _gradient = '';

  categories.forEach((c, idx) => {
    if (typeof c === 'function' && c().label === category) {
      _gradient = backgroundGradients[idx];
    }
  });

  return _gradient;
};

export const getBackgroundMessages = () => {
  const backgroundMessages = [
    "Sit back and relax, we're working on changing the background to your videos!",
    "We know it can be a drag to wait, but don't worry - your videos won't be waiting for long!",
    "We promise it won't take forever! Hang tight while we finish up the background change!"
  ];

  return backgroundMessages[randomIntFromInterval(0, 2)];
};

export const getVoiceOverMessages = () => {
  const voiceoverMessages = [
    "Hang tight, we're making sure your videos get the perfect voice-over!",
    "If you're getting antsy, why not grab a cup of coffee while you wait?",
    "It's almost time to hear your videos come to life with a new voice-over!"
  ];

  return voiceoverMessages[randomIntFromInterval(0, 2)];
};

export const getErrorMessages = () => {
  const errorMessages = [
    "Videos didn't make it. Don't cry over spilled clips! 🤦",
    'Videos? Nope. Try again! 🤕',
    "Oops! We couldn't make the video. Time to grab some popcorn and get creative! 🤦‍♀️",
    'Oops! Video creation failed. Time to grab some popcorn and start editing! 🤒',
    "Oops! Looks like your video didn't make the cut. Better luck next time! 🤦‍♀️"
  ];

  return errorMessages[randomIntFromInterval(0, 4)];
};

export const makePaymentStripe = (handlePayment) => {
  // TODO: Prices by countries
  // (["ca", "us", "be", "dk", "fi", "fr", "de", "ie", "li", "lu", "nl", "no", "se", "ch", "uk"])
  // DEV - price_1Mr1b2HGp0zRWiA8D3tkyNWZ
  // PROD - price_1MtE9ZHGp0zRWiA8q9INpTdp

  let _id = '';
  if (API_BROWSE.indexOf('api-dev') !== -1) {
    // DEV - default
    _id = 'price_1MrK3PHGp0zRWiA8iDlZ7Y9h';
  } else {
    // PROD - default
    _id = 'price_1NBHGVHGp0zRWiA8YXOToHry';
  }

  handlePayment({ id: _id });
};

export const getTimesByVoiceOver = (sectionVideos, voiceover) => {
  let _timesByVoiceOver = [];

  sectionVideos.forEach((video, idx) => {
    _timesByVoiceOver[idx] = { scenes: [] };

    let activeWord = 0;
    const timePoints = voiceover[idx].audio.wordBoundaryTimepoints;

    video.scenes.forEach((scene, i) => {
      let times = [];

      const captions = scene.captions.replace(/~\s*$/, '').split('~');
      captions.forEach((c) => {
        const word = c.replaceAll('*', '');

        let time = {
          startTime: timePoints[activeWord]?.timeSeconds,
          endTime: null
        };

        let condition = true;
        while (condition) {
          const _word = timePoints[activeWord]?.word;
          if (word.includes(_word)) {
            activeWord = activeWord + 1;
          } else {
            condition = false;
            time.endTime =
              timePoints[activeWord - 1]?.timeSeconds + timePoints[activeWord - 1]?.duration;
          }
        }

        times.push(time);
      });

      _timesByVoiceOver[idx].scenes[i] = { duration: 0, times: times };
    });
  });

  _timesByVoiceOver.map((v, vIdx) => {
    v.scenes.map((s, i) => {
      if (i < v.scenes.length - 1) {
        s.duration =
          _timesByVoiceOver[vIdx].scenes[i + 1].times[0].startTime - s.times[0].startTime + 1;
      } else {
        s.duration = s.times[s.times.length - 1].startTime - s.times[0].startTime + 1.5;
      }
    });
  });

  _timesByVoiceOver.map((v) => {
    v.scenes.map((s, sIdx) => {
      s.times.map((t, tIdx) => {
        t.start = t.startTime - s.times[0].startTime;
        if (sIdx === 0) {
          t.start = t.startTime;
          if (tIdx === 0) t.start = 0;
          if (tIdx < s.times.length - 1) t.stop = s.times[tIdx + 1].startTime;
          else t.stop = t.endTime;
        } else {
          t.start = tIdx === 0 ? 0 : t.startTime - s.times[0].startTime;
          if (tIdx < s.times.length - 1)
            t.stop = s.times[tIdx + 1].startTime - s.times[0].startTime;
          else t.stop = t.endTime - s.times[0].startTime;
        }
      });
    });
  });
  // console.log('_timesByVoiceOver', _timesByVoiceOver);

  return _timesByVoiceOver;
};

export const getIdeasForYou = (sections, categories) => {
  let _ideas = [];

  sections.forEach((section) => {
    categories.forEach((category) => {
      if (category().label !== section.category && _ideas.indexOf(category().label) === -1) {
        _ideas.push(category().label);
      }
    });
  });

  if (_ideas.length !== 0) {
    _ideas = _ideas.sort(() => 0.5 - Math.random());
    if (sections.length <= 3) _ideas.length = 4 - sections.length;
    else _ideas.length = 1;
  }

  return _ideas;
};

export const getDayLabels = (daysToPost) => {
  let label = '';

  daysToPost.forEach((day) => {
    if (day.active) {
      label = label + day.day + ' ';
    }
  });

  label = label.trim();

  if (label === '') label = 'No selected';

  return label;
};

export const getTimeLabels = (timeToPost) => {
  let label = '';

  timeToPost.forEach((time) => {
    if (time.active) {
      label = label + convertTime(time.time) + ' ';
    }
  });

  label = label.trim();

  if (label === '') label = 'No selected';

  return label;
};

export const convertTime = (time24) => {
  var elements = time24.split(':');
  var hours = elements[0];
  var minutes = elements[1];
  var newFormat = '';

  if (hours == 0) {
    newFormat = '12:' + minutes + ' ' + 'AM';
  } else if (hours == 12) {
    newFormat = hours + ':' + minutes + ' ' + 'PM';
  } else if (hours > 12) {
    hours = hours - 12;
    newFormat = hours + ':' + minutes + ' ' + 'PM';
  } else {
    newFormat = hours + ':' + minutes + ' ' + 'AM';
  }

  return newFormat;
};

export const orderVideosByScheduleDate = (input, optimizeVideo = false) => {
  let videosToSchedule = [];

  let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  for (let i = 0; i < input.length; i++) {
    let date = new Date(input[i].publicationMetadata.scheduleDate);
    let video = input[i];

    if (optimizeVideo) {
      let editSpec = video.editSpec;
      delete editSpec.audioTracks;
      delete editSpec.customFonts;
      editSpec.clips.length = 1;
      editSpec.clips[0].layers.length = 2;

      video = {
        ...video,
        editSpec: editSpec
      };
    }

    let dayOfWeek = daysOfWeek[date.getUTCDay()];

    if (
      videosToSchedule.length > 0 &&
      videosToSchedule[videosToSchedule.length - 1].day === dayOfWeek
    ) {
      videosToSchedule[videosToSchedule.length - 1].videos.push(video);
    } else {
      videosToSchedule.push({
        day: dayOfWeek,
        videos: [video]
      });
    }
  }
  return videosToSchedule;
};
